import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { HttpClientModule } from "@angular/common/http";
import {RouterActivatedMatListItemDirective} from "./utils/RouterActivatedMatListItemDirective";
import { UserManagementComponent } from './user-management/user-management.component';
import {CompanypartnersRoutingModule} from "./companypartners-routing.module";
import {ConsumptionComponent} from "./consumption/consumption.component";
import { CompanypartnersAppComponent } from './app/companypartners-app.component';
import { MeteringPointsComponent } from './metering-points/metering-points.component';
import { ReportsComponent } from './reports/reports.component';
import { AccountComponent } from './account/account.component';
import { CompanySettingsComponent } from './company-settings/company-settings.component';
import {CompanypartnersBaseComponent} from "./companypartners-base.component";
import { UniteCompaniesDialogComponent } from './unite-companies-dialog/unite-companies-dialog.component';
import { AddUserDialogComponent } from './add-user-dialog/add-user-dialog.component';
import { UserDetailsDialogComponent } from './user-details-dialog/user-details-dialog.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { ChangeProfileComponent } from './account/change-profile/change-profile.component';
import {MaterialModule} from "../shared/material-components/material.module";
import {SharedModule} from "../shared/modules/shared.module";
import { MeteringPointInfoComponent } from './metering-point-info/metering-point-info.component';
import { NavDropdownComponent } from './nav-dropdown/nav-dropdown.component';
import { ContingentTradeComponent } from './contingent-trade/contingent-trade.component';
import { ContingentTradeInfoComponent } from './contingent-trade-info/contingent-trade-info.component';

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    SharedModule,
    MaterialModule,
    CompanypartnersRoutingModule,
    RouterActivatedMatListItemDirective,
  ],
  declarations: [
    ConsumptionComponent,
    CompanypartnersAppComponent,
    UserManagementComponent,
    MeteringPointsComponent,
    ReportsComponent,
    AccountComponent,
    CompanySettingsComponent,
    CompanypartnersBaseComponent,
    UniteCompaniesDialogComponent,
    AddUserDialogComponent,
    UserDetailsDialogComponent,
    ChangePasswordComponent,
    ChangeProfileComponent,
    MeteringPointInfoComponent,
    NavDropdownComponent,
    ContingentTradeComponent,
    ContingentTradeInfoComponent
  ],
  exports: [
    CompanypartnersAppComponent
  ]
})
export class CompanypartnersModule { }
