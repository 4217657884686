<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <mat-checkbox
      [checked]="node.selected"
      [indeterminate]="node.selected === null"
      (change)="setParentNode(node, $event.checked)"
      [id]="'filterTree_'+node.label">
        {{node.label}}
    </mat-checkbox>&nbsp;
    <mat-icon *ngIf="node.data.flagged" svgIcon="info-circle" class="text-info" [matTooltip]="'MeteringPoints.IsExcludedFromContingent' | translate"></mat-icon>
  </mat-tree-node>

  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding class="flex-spaced">
    <mat-checkbox
      [checked]="node.selected"
      [indeterminate]="node.selected === null"
      (change)="setAllSubnodes(node, $event.checked)"
      [id]="'filterTree_'+node.label">
        {{node.label}}
    </mat-checkbox>
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" [id]="'expandTree_'+node.label">
      <mat-icon class="mat-icon-rtl-mirror" [svgIcon]="treeControl.isExpanded(node) ? 'chevron-up' : 'chevron-down'"></mat-icon>
    </button>
  </mat-tree-node>
</mat-tree>
