import { Pipe, PipeTransform } from '@angular/core';
import {DateTimeService} from "./date-time.service";

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  constructor(private dateTimeService: DateTimeService) {
  }
  transform(value: {year: number, month: number, day: number}, ..._args: unknown[]): unknown {
    return this.dateTimeService.getDateAsLocaleFormatLinux(new Date(value.year, value.month-1, value.day).getTime());
  }

}
