/* eslint-disable @angular-eslint/component-selector */
import {Component, ViewChild} from '@angular/core';
import {UtilsService} from '../../shared/services/utils.service';
import {SessionService} from '../../shared/session/session.service';
import {NotificationsService} from '../../shared/services/notifications.service';

import {ActivatedRoute, Router} from '@angular/router';
import {SmartModalService} from '../../shared/marketpartner-components/smart-forms/smart-modal/service/smart-modal.service';
import {SmartTableBuilder} from '../../shared/marketpartner-components/smart-forms/smart-table/builder/smart-table.builder';
import {SmartTableConfigDefinition} from '../../shared/marketpartner-components/smart-forms/smart-table/classes/SmartTableConfigDefinition';
import {StandardViewComponent} from '../../shared/marketpartner-components/standard-view/standard-view.component';
import {MOCKUP_RESPONSES_LIST} from '../requests/mockup-responses-list';
import {SmartTableComponent} from '../../shared/marketpartner-components/smart-forms/smart-table/component/smart-table.component';

import {ActionEvent} from '../../shared/marketpartner-components/smart-forms/smart-shared/classes/ActionEvent';
import {UserInfoServer} from '../../shared/session/UserInfo';
import {MarketPartnerInfo, Feature} from '../../shared/session/MarketPartnerInfo';
import {AppConfig} from '../../config/app.config';
import {SmartFormBuilder} from '../../shared/marketpartner-components/smart-forms/smart-form/builder/smart-form.builder';
import {SmartModalBuilder} from '../../shared/marketpartner-components/smart-forms/smart-modal/builder/smart-modal.builder';
import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';
import {ApiRequestBuilder} from '../../shared/api-request/builder/api-request.builder';
import {ApiRequestService} from '../../shared/api-request/service/api-request.service';
import {EnumItem} from '../../shared/services/enum-utils/classes/enum-definition';
import {DeleteMeteringdataDialogComponent} from "./delete-meteringdata-dialog/delete-meteringdata-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {
  AdminOstralLettersDownloadDialogComponent
} from "../admin-ostral-letters-download-dialog/admin-ostral-letters-download-dialog.component";
import {AdminOstralLettersDialogComponent} from "../admin-ostral-letters-dialog/admin-ostral-letters-dialog.component";


@Component({
  selector: 'page-admin-marketpartners-content',
  templateUrl: './admin-marketpartners.component.html',
  styleUrls: []
})

export class AdminUsersMarketPartnersComponent extends StandardViewComponent {
  @ViewChild('tableMarketPartners') tableMarketPartners?: SmartTableComponent;


  initialized = false;

  tableMarketPartnersConfig?: SmartTableConfigDefinition;
  dataSetMarketPartnersUsers: UserInfoServer[] = [];
  mockUpResponsesCopy: any = null;

  constructor(public sessionService: SessionService,
              public activatedRoute: ActivatedRoute,
              private apiRequestService: ApiRequestService,
              private notificationsService: NotificationsService,
              private utilsService: UtilsService,
              public smartModalService: SmartModalService,
              public router: Router,
              public dialog: MatDialog) {
    super(activatedRoute, router);

    this.mockUpResponsesCopy = this.utilsService.cloneJSON(MOCKUP_RESPONSES_LIST);
    this.onListChanged = this.onListChanged.bind(this);

    this.tableMarketPartnersConfig = new SmartTableBuilder()
      .setColumnNameForId('partnerSettings:name')
      .addColumnMarketPartnerName()
      .addColumnMarketPartnerEIC()
      .addColumnMarketPartnerSDATRole()

      .addColumnMarketPartnerContractStatus()
      .addInitialSorting({columnId: 'partnerSettings:name', order: 'asc'})
      .addToolbarActionFromInfo({
        id: 'register_marketpartner',
        title: 'ActionCreateNewMarketPartner',
        icon: 'pli-add-user',
        className: 'btn-primary',
        needsEntrySelected: false
      })
      .addToolbarActionFromInfo({
        id: '',
        title: 'ActionManageFeatures',
        icon: 'pli-beta',
        className: 'btn-primary',
        fnClicked: () => this.featuresMarketPartner(null)
      })
      .addToolbarActionFromInfo({
        id: '',
        title: 'ActionDownloadOperatorReport',
        icon: 'pli-download',
        className: 'btn-primary',
        fnClicked: () => this.download()
      })
      .addToolbarActionFromInfo({
        id: '',
        title: 'Contingents.lettersDownload',
        icon: 'pli-download',
        className: 'btn-primary',
        fnClicked: () => this.openDownloadDialog()
      })


      .addRowActionViewMarketPartner()
      .addRowActionSetMarketPartnerContract()
      .addRowActionFromInfo({
        id: 'features_marketpartner',
        title: 'Features verwalten',
        icon: 'pli-beta',
        className: 'btn-mint'
      })
      .addRowActionFromInfo({
        id: 'downloadDataForContingencyLetter',
        title: 'Ostral.DownloadDataForContingencyLetter',
        icon: 'pli-plug-in',
        className: 'btn-danger'
      })
      .addRowActionDeleteMeteringPoints()
      .addRowActionFromInfo({
        id: 'delete_meteringdata',
        title: 'Messdaten löschen',
        icon: 'pli-data',
        className: 'btn-danger',
        fnVisible: rowData => rowData['partnerSettings:role'] === 'VNB'
      })
      .addRowActionDeleteMarketPartner()

      .setApiRequestConfigInfoFromInfo(USERS_API_ENDPOINTS_LIST.marketpartnerDirectorySearch)
      .showFilters(true)
      .showSearchField(false)
      .build();
  }

  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);
    await this.loadAllData();
    this.setInitialized(true);
  }

  async loadAllData() {
    await Promise.all([
      // this.loadUsersData()
    ]);
  }

  /* async loadUsersData() {
    this.dataSetMarketPartnersUsers = this.mockUpResponsesCopy.allMarketPartnersResponseData;

    return this.dataSetMarketPartnersUsers;
  } */

  onRowClicked(_event: any) {
  }

  async onListChanged() {
    if (this.tableMarketPartners) {
      this.tableMarketPartners.reload();
    }

    /* TODO: const componentData = await this.loadUsersData();
    if (this.tableMarketPartners) {
      this.tableMarketPartners.reloadNewData(componentData);
    } */
  }

  async onActionPerformed(event: ActionEvent) {
    const self = this;
    const entitySelected = event.data.length ? event.data[0] : null;

    switch (event.actionId) {
      case 'view_marketpartner':
        self.viewMarketPartner(entitySelected);
        break;
      case 'register_marketpartner':
        self.registerMarketPartner();
        break;
      case 'features_marketpartner':
        self.setFeaturesMarketPartner(entitySelected);
        break;
      case 'setcontract_marketpartner':
        self.setContractMarketPartner(entitySelected);
        break;
      case 'delete_meteringpoints':
        self.deleteMeteringPoints(entitySelected);
        break;
      case 'delete_meteringdata':
        self.deleteMeteringdata(entitySelected);
        break;
      case 'delete_marketpartner':
        self.deleteMarketPartner(entitySelected);
        break;
      case 'downloadDataForContingencyLetter':
        self.openLetterDialog(entitySelected);
        break;
    }
  }

  /* All possible actions */
  viewMarketPartner (marketPartnerInfo: MarketPartnerInfo ) {
    this.navigateTo(
      {
        'route': AppConfig.routes.admin_marketpartner_communication_webclient,
        'replace': {'partnerId': marketPartnerInfo.partnerSettings.partnerId}
      });
  }

  async deleteMeteringdata(marketPartnerInfo: MarketPartnerInfo) {
    this.dialog.open(DeleteMeteringdataDialogComponent, {
      data: {
        partnerId: marketPartnerInfo.partnerSettings.partnerId,
        partnerEic: marketPartnerInfo.partnerSettings.eic,
        partnerName: marketPartnerInfo.partnerSettings.name
      }
    });
  }

  async deleteMarketPartner (marketPartnerInfo: MarketPartnerInfo ) {
      const apiRequestConfig = new ApiRequestBuilder()
        .setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryMarketPartnerDelete,
                         {partnerId: marketPartnerInfo.partnerSettings.partnerId})
        .setHandleLoading(true)
        .setHandleErrorNotification(true)
        .build();

      const resultApiRequest = await this.apiRequestService.callApi(apiRequestConfig);
      if (resultApiRequest.status === 'success') {
        this.onListChanged();
      }
  }

  async deleteMeteringPoints (marketPartnerInfo: MarketPartnerInfo ) {
    const apiRequestConfig = new ApiRequestBuilder()
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryMeteringPointsDelete,
                       {partnerId: marketPartnerInfo.partnerSettings.partnerId})
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .build();

    const result = await this.apiRequestService.callApi(apiRequestConfig);
    if (result.status === 'success') {
      // feedback required because the deleted item does not disappear
      this.notificationsService.showNotification({
        type: 'success',
        message: 'LabelDeleteMeteringPointsSuccess'
      });
    }
  }

  setContractMarketPartner(marketPartnerInfoTableRow: MarketPartnerInfo) {
    const self = this;
    // TODO: Replace&delete remaining .addFormField*() methods with
    // .addFormFieldFromInfo() if they are only used here.
    const formConfig = new SmartFormBuilder()
      .addFormFieldMarketPartnerNameReadOnly()
      .addFormFieldMarketPartnerEICReadOnly()
      .addFormFieldMarketPartnerSDATRoleReadOnly()
      .addFormFieldMarketPartnerContactEmailReadOnly()
      .addFormFieldMarketPartnerContactPhoneReadOnly()
      .addFormFieldMarketPartnerContractStatus()
      // .addFormFieldMarketPartnerContactEmailHidden()
      .setInitData({partnerSettings: marketPartnerInfoTableRow.partnerSettings}, true)
      .setApiSendDataRequestConfigFromInfo(new ApiRequestBuilder().setEndpointInfo(
        USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryMarketPartnerSettingsUpdate,
        {partnerId: marketPartnerInfoTableRow.partnerSettings.partnerId}).build())
      .showSubmitButton(true)
      .showCancelButton(true)
      .build();
    const modalOptions = new SmartModalBuilder()
      .setOnSuccessForm(async function (_submittedForm: any) {
        self.onListChanged();
      })
      .setOnSuccessResponse(async function (_serverResponseData: any) {

      })
      .setTitle('ActionSetMarketPartnerContract')
      .setModalCSSClassSize('md')
      .setFormConfigFromInfo(formConfig)
      .build();

    this.smartModalService.showModal(modalOptions);
  }

  registerMarketPartner() {
    const self = this;
    // TODO: Replace&delete remaining .addFormField*() methods with
    // .addFormFieldFromInfo() if they are only used here.
    const formConfig = new SmartFormBuilder()
      .addFormFieldMarketPartnerName()
      .addFormFieldFromInfo({
        name: 'partnerSettings:eic', type: 'input',
        title: 'FormEIC', cssClasses: 'col-sm-12',
        validation: {
          type: 'eic',
          required: true,
        },
      })
      .addFormFieldMarketPartnerSDATRole()

      .addFormFieldMarketPartnerContactEmail()
      .addFormFieldMarketPartnerContactPhone()
      .addFormFieldMarketPartnerContractStatus()
      .addFormFieldMarketPartnerLanguage()

      .addFormFieldMarketPartnerInitialAdminEmail()

      .setApiSendDataRequestConfigFromInfo(new ApiRequestBuilder().setEndpointInfo(
        USERS_API_ENDPOINTS_LIST.marketpartnersCreate).build())
      .showSubmitButton(true, 'ButtonCreate')
      .showCancelButton(true)
      .build();

    const modalOptions = new SmartModalBuilder()
      .setOnSuccessForm(async function (_submittedForm: any) {
        self.onListChanged();
      })
      .setTitle('ActionCreateNewMarketPartner')
      .setModalCSSClassSize('md')
      .setFormConfigFromInfo(formConfig)
      .build();

    this.smartModalService.showModal(modalOptions);
  }

  setFeaturesMarketPartner(mpi?: MarketPartnerInfo) {
    if (!mpi || !mpi.partnerSettings.partnerId) return;
    this.featuresMarketPartner(mpi.partnerSettings.partnerId);
  }

  featuresMarketPartner(partnerId: (number | null)) {
    const reqBuilder = new ApiRequestBuilder();
    if (partnerId == null) {
      reqBuilder
        .setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryFeaturesForAll);
    } else {
      reqBuilder
        .setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryMarketPartnerFeatures, {partnerId});
    }

    // UI-Selectable features (omitting null/reserved values from .proto enum)
    // "features": ["FEATURE_1", "FEATURE_2"]
    const features: Feature[] = ['OSTRAL'];
    const removableFeatures: string[] = [
      ...features
    ];
    const featuresEnum: EnumItem<Feature>[] = features.map(label => ({label, data: label}));
    const removableFeaturesEnum: EnumItem<string>[] = removableFeatures.map(label => ({label, data: label}));
    const self = this;
    const formConfig = new SmartFormBuilder()
      .addFormFieldFromInfo({
        name: 'features', title: 'Zustand:',
        type: 'checkboxmulti', optionsAsList: true,
        omitFromRequest: true,
        disabled: true,
        // readOnly: true,  // broken
        validation: {required: false},
        cssClasses: 'chechboxes-2-columns',
        allowedValues: {type: 'localDefined', data: removableFeaturesEnum}
      })
      .addFormFieldFromInfo({
        name: 'toAdd', title: 'Hinzufügen:',
        type: 'checkboxmulti', optionsAsList: true,
        cssClasses: 'chechboxes-2-columns',
        allowedValues: {type: 'localDefined', data: featuresEnum}
      })
      .addFormFieldFromInfo({
        name: 'toRemove', title: 'Entfernen:',
        type: 'checkboxmulti', optionsAsList: true,
        cssClasses: 'chechboxes-2-columns',
        allowedValues: {type: 'localDefined', data: removableFeaturesEnum}
      })
      .setApiGetDataRequestConfigFromInfo(reqBuilder.build())
      .setApiSendDataRequestConfigFromInfo(reqBuilder.build())
      .showSubmitButton(true, 'Ausführen')
      .setSubmitDangerous(partnerId == null)
      .showCancelButton(true)
      .build();
    const modalOptions = new SmartModalBuilder()
      .setOnSuccessForm(async function (_submittedForm: any) {
        self.onListChanged();
      })
      .setTitle(partnerId == null ? 'Features für ALLE Marketpartner' : 'Features')
      .setModalCSSClassSize('md')
      .setFormConfigFromInfo(formConfig)
      .build();

    this.smartModalService.showModal(modalOptions);
  }

  async download() {
    await this.apiRequestService.downloadFile(USERS_API_ENDPOINTS_LIST.operatorReport);
  }

  openLetterDialog(entitySelected: MarketPartnerInfo) {
    this.dialog.open(AdminOstralLettersDialogComponent, {
      data: {
        partnerId: entitySelected.partnerSettings.partnerId,
      },
    });
  }

  async openDownloadDialog() {
    this.dialog.open(AdminOstralLettersDownloadDialogComponent, {
      data: {},
    });
  }
}
