/* eslint-disable guard-for-in */
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

import {SessionService} from '../../session/session.service';
import {environment} from '../../../../environments/environment';
import {NotificationsService} from '../../services/notifications.service';
import {ApiRequestConfigDefinition} from '../classes/ApiRequestConfigDefinition';
import {ApiRequestConfigInfoDefinition, HttpMethod} from '../classes/ApiRequestConfigInfoDefinition';
import {NameValueMap} from '../../marketpartner-components/smart-forms/smart-shared/classes/KeyValueMap';
import {ApiRequestStandardResponse} from '../classes/ApiRequestStandardResponse';
import {ApiRequestMockupInterceptorService} from './api-request-mockup-interceptor.service';
import {UtilsService} from '../../services/utils.service';
import {CustomTranslateService} from '../../services/custom-translate.service';


@Injectable()
export class ApiRequestService {
  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private utilsService: UtilsService,
    private apiRequestMockupInterceptorService: ApiRequestMockupInterceptorService,
    private notificationsService: NotificationsService,
    private customTranslateService: CustomTranslateService
  ) {}

  private sanitizeUrlPart(part: string | number): string {
    const s = part + '';
    // We don't want stuff like '../../' in our API path. Not using
    // encodeURIComponent() because it doesn't prevent '../' from being
    // interpreted as path on the server-side.
    // allow %20 escaped space
    return s.replace(/[^0-9a-zA-Z%_-]|((?!%20)%)/g, '');
  }

  private getUrlFromInfo(apiCallInfo: ApiRequestConfigInfoDefinition) {
    let url = apiCallInfo.endpoint;
    if ('replaceUrl' in apiCallInfo && apiCallInfo.replaceUrl) {
      for (const replaceKey in apiCallInfo.replaceUrl) {
        url = url.replace( ('{' + replaceKey + '}'), this.sanitizeUrlPart(apiCallInfo.replaceUrl[replaceKey]));
      }
    }
    if (environment.apiURL) {
      url = environment.apiURL + url;
    }
    return url;
  }

  private async getHeaders(apiCallInfo: ApiRequestConfigInfoDefinition): Promise<HttpHeaders|null> {
    if (apiCallInfo.tokenNotRequired) {
      return new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.customTranslateService.getCurrentLangShort()
      });
    }
    let token = apiCallInfo.overrideToken;
    if (!token) {
      token = await this.sessionService.getAccessToken();
    }
    if (!token) return null;
    return  new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
      'Accept-Language': this.customTranslateService.getCurrentLangShort()
    });
  }

  async callApi(fullApiInfo: ApiRequestConfigDefinition): Promise<ApiRequestStandardResponse | any> {
    const apiCallInfo =  fullApiInfo.info;
    const value = fullApiInfo.body;
    const options = fullApiInfo.options;
    if (!apiCallInfo) {
      return undefined;
    }
    return this.doApiRequest(apiCallInfo, value, options.handleLoading ,  options.handleErrorNotification);
  }

  async doApiRequest(apiCallInfo: ApiRequestConfigInfoDefinition, body?: NameValueMap<any> | FormData,
                     handleLoading = false, handleErrorNotification = false): Promise<ApiRequestStandardResponse | any> {

    if (body === undefined) {
      body = {};
    }
    const self = this;
    let thisNotificationId: string;
    let promiseRequest: Promise<any>;
    if (apiCallInfo.useMockup) {
      promiseRequest = Promise.resolve(this.apiRequestMockupInterceptorService.getMockupServiceData(apiCallInfo, body));
      // return this.apiRequestMockupInterceptorService.getMockupServiceData(apiCallInfo, body);
    } else {
      let headers = await this.getHeaders(apiCallInfo);
      if (!headers) {
        if (handleErrorNotification) {
          self.notificationsService.showNotification( {type: 'error', message: 'SessionExpiredError'});
        }
        return {status: 'error', data: 'SessionExpiredError'};
      } else {
        if (body instanceof FormData) {
          // Angular does the Right Thing (TM)
          headers = headers.delete('Content-Type');
        }
      }

      let method: HttpMethod = 'POST';
      if (apiCallInfo.method) {
        method = apiCallInfo.method;
      }
      const urlApiEndpoint = this.getUrlFromInfo(apiCallInfo);

      if (handleLoading) {
        thisNotificationId = this.notificationsService.showNotification(
          {type: 'loading', message: 'NotificationPleaseWait', delayShowMs: 3000}
        );
      }

      if (!urlApiEndpoint) throw new Error('ApiRequest without endpoint');

      switch (method) {
        case 'POST':
          promiseRequest = this.http.post<any>(urlApiEndpoint, body, { headers: headers }).toPromise();
          break;
        case 'GET':
          // If value, add it as parameters like ?xxx=111&yyyy=2222, etc..
          let strParams = '';
          if (body instanceof FormData) {
            throw new Error('GET with FormData upload is not supported.');
          }
          if (body) {
            for (const key in body) {
              if (strParams !== '') {
                strParams += '&';
              }
              strParams += key + '=' + encodeURIComponent(body[key]);
            }
          }
          promiseRequest = this.http.get<any>(urlApiEndpoint + (strParams ? '?' + strParams : ''), { headers: headers }).toPromise();
          break;
        case 'PUT':
          promiseRequest = this.http.put<any>(urlApiEndpoint, body, { headers: headers}).toPromise();
          break;
        case 'DELETE':
          promiseRequest = this.http.delete<any>(urlApiEndpoint, { headers: headers}).toPromise();
          break;
      }
    }

    if (!promiseRequest) {
      return undefined;
    }

    return await promiseRequest
      .then(response => {
        if (response == null) throw new Error('empty HTTP response');
        if ('dataInfo' in response) {
          switch (response.dataInfo.type) {
            case 'file':
              self.utilsService.createDownloadabeFileFromResponseAndSave(response);
              break;
          }
        }
        if (thisNotificationId) {
          self.notificationsService.hideNotification(thisNotificationId);
        }
        return response;
      })
      .catch(async function(error) {
        if (thisNotificationId) {
          self.notificationsService.hideNotification(thisNotificationId);
        }
        if (error.status === 401 && !apiCallInfo.allowAuthErrors) {
          console.log('invalidateSession() because of 401.', error);
          self.sessionService.invalidateSession();
        }
        const errorCodeString = await self.convertApiErrorToString(error);
        if (errorCodeString === 'InvalidAccessRights' && !apiCallInfo.allowAuthErrors) {
          console.log('invalidateSession() because of access rights.', error);
          self.sessionService.invalidateSession();
        }
        if (handleErrorNotification) {
          self.notificationsService.showNotification( {type: 'error', message: errorCodeString});
        }
        return {
          status: 'error',
          data: errorCodeString
        };
      });
  }

  public async convertApiErrorToString(error: any): Promise<string> {
    if (error instanceof HttpErrorResponse) {
      let fallbackMessage = 'NetworkError: ' + error.message;  // this includes the request URL
      if (error.error) {
        const userMessages = error.error.userMessages;
        if (userMessages !== undefined) {
          const lang = this.customTranslateService.getCurrentLangShort();
          if (userMessages[lang]) return userMessages[lang];
        }
        if (error.error.message) {
          fallbackMessage = error.error.message;
        }
        console.error(error);
        // Additional print for developer-proxy CORS errors
        console.error(error.error);
      }
      if (error.status === 413) return 'EntityTooLargeError';
      if (error.status === 403) return 'HttpForbidden';
      return fallbackMessage;
    } else if (error instanceof Response) {
      console.error('This code should be unreachable? Is it?');
      console.error(error);
      const errorResponseJson = await error.json();
      return errorResponseJson.error || 'backend server error';
    } else {
      if(error.result?.errorMessages) {
        const lang = this.customTranslateService.getCurrentLangShort();
        const msgs = error.result.errorMessages.map((m: any) => m[lang]);
        if(msgs.length > 1) {
          return msgs.map((m: string, i: number) => (i + 1) + '. ' + m).join(' ');
        } else {
          return msgs[0];
        }
      }
      // Most likely a JavaScript exception. (Or possibly something else?)
      console.error(error);
      try {
        return error.toString();
      } catch {
        return 'RUNTIME ERROR';
      }
    }
  }

  public async downloadFile(apiCallInfo: ApiRequestConfigInfoDefinition, params?: NameValueMap<string>) {
    console.log('downloadFile() with params:', params);
    // We could use a link, but then we can't control the header to add the
    // access token. We could download a blob, but we'd have to implement custom
    // progress, user can't select location until downloaded, etc.
    // https://stackoverflow.com/questions/24501358/how-to-set-a-header-for-a-http-get-request-and-trigger-file-download/24523253#
    //
    // This gets the browser-native HTTP download.
    const accessToken = await this.sessionService.getAccessToken();
    if (!accessToken) return;
    const form = document.createElement('form');
    form.action = this.getUrlFromInfo(apiCallInfo);
    form.method = apiCallInfo.method.toLowerCase();

    params = {'Authorization': accessToken, ...params};
    for (const key in params) {
      const field = document.createElement('input');
      field.type = 'hidden';
      field.name = key;
      field.value = params[key];
      form.appendChild(field);
    }

    document.body.appendChild(form);
    form.submit();
  }

  public async selectFile(accept: string): Promise<File | null> {
    const input = document.createElement('input');
    input.type = 'file';
    input.setAttribute('style', 'display:none;');
    input.accept = accept;
    document.body.appendChild(input);
    const fileSelected = new Promise(function(resolve) {
      input.onchange = resolve;
    });
    input.click();
    await fileSelected;

    if (!input.files) {
      console.log('no file selected');
      return null;
    }
    console.log('input.value:', input.value);
    return input.files[0];
  }
}
