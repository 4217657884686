import {AdditionalChartInfo, ChartDataModel} from "./chart-data-model";
import {
  ConsumptionDataCompanyPartner,
  ConsumptionDataForYear, TradedContingents
} from "./consumption-data-model";
import {TreeNode} from "../material-components/filter-tree/filter-tree.component";
import {TranslateService} from "@ngx-translate/core";
export class ChartDataMapper {
  public static toChartDatasetData(translate: TranslateService,
                                   data: ConsumptionDataCompanyPartner | undefined,
                                   yearToDisplay: number,
                                   monthToDisplay: number | undefined = undefined,
                                   meteringPointFilter: {mpid: string, vnb: string, bp: string}[] | undefined = undefined,
                                   getValuesForMonth: boolean = false) {

    let chartData: ChartDataModel = new ChartDataModel();
    if(data == undefined || monthToDisplay == undefined && getValuesForMonth) {
      chartData.visibleContingentData = [];
      chartData.soldContingentData = [];
      chartData.purchasedContingentData = [];
      chartData.currentData = [];
      chartData.previousData = [];
      chartData.additionalInfoCurrent = [];
      chartData.additionalInfoPrevious = [];
      return chartData;
    }

    const filteredBusinessPartners = (data.businessPartners || [])
      .filter(bp => meteringPointFilter == undefined || meteringPointFilter.filter(f => f.bp === bp.name).length > 0); // only selected businesspartners

    const pointDataFiltered = filteredBusinessPartners
      .flatMap(bp => bp.meteringPoints)  // array of all meteringPoints for all relevant businessPartners
      .filter(mp => meteringPointFilter == undefined || meteringPointFilter.map(f => f.mpid+f.vnb).includes(mp.id+mp.mpa.id)) // only selected MPs
      .flatMap(mp => mp.dataForYear); // strip MPID -> array of consumptionDataForYear with possibly multiple entries per year

    const contingentTrades = filteredBusinessPartners.flatMap(bp => bp.tradedContingents);

    chartData.purchasedContingentData = this.getContingentTradesForPeriod(contingentTrades, 'PURCHASE', yearToDisplay, getValuesForMonth ? monthToDisplay : undefined);

    chartData.soldContingentData = this.getContingentTradesForPeriod(contingentTrades, 'SALE', yearToDisplay, getValuesForMonth ? monthToDisplay : undefined);
    //console.log("SOLD", chartData.soldContingentData);

    //console.log("_________________ start mapping [current]");
    chartData.currentData = this.sumMpDataForPeriod(pointDataFiltered, yearToDisplay, false, getValuesForMonth ? monthToDisplay : undefined);
    //console.log("_________________ start mapping [previous]");
    chartData.previousData = this.sumMpDataForPeriod(pointDataFiltered, yearToDisplay - 1, false, getValuesForMonth ? monthToDisplay : undefined);
    //console.log("_________________ start mapping [contingent]");
    chartData.visibleContingentData = this.sumMpDataForPeriod(pointDataFiltered, yearToDisplay, true, getValuesForMonth ? monthToDisplay : undefined, chartData.purchasedContingentData, chartData.soldContingentData);

    //console.log("_________________ start mapping [additional current]");
    chartData.additionalInfoCurrent = this.generateCompleteness(translate, pointDataFiltered, yearToDisplay, getValuesForMonth ? monthToDisplay : undefined);
    //console.log("_________________ start mapping [additional previous]");
    chartData.additionalInfoPrevious = this.generateCompleteness(translate, pointDataFiltered, yearToDisplay - 1, getValuesForMonth ? monthToDisplay : undefined);

    return chartData;
  }

  private static getNumOfValues(year: number, month: number | undefined) {
    return month === undefined ? 12 :
      new Date(year, month + 1, 0).getDate(); // day 0: last day of previous month. month + 1 to get last day of displayed month
  }

  private static getValuesForRelevantTimePeriod(data: ConsumptionDataForYear[], year: number, calculateContingent: boolean, monthIndex: number | undefined, calculateDataSource: boolean = false)
  : (string | null)[][] | (number | null)[][] {
    //console.log("preparing values ", year, monthIndex, dataSource, data);
    let dataForRelevantYear = data
      .filter(dfy => dfy.year == year);
    if(dataForRelevantYear.length == 0) {
      return [];
    }

    // all data for the same year anyway, only interested in values (contingent, dailyValues, monthlyValues per meteringPoint) now. strip outer structure(s).

    // contingent
    if(calculateContingent) {
      //console.log("CONTINGENT", dataForRelevantYear.map(dfcy => dfcy.contingents));
      const contingentsForYearPerMP = dataForRelevantYear.map(dfcy => (dfcy.contingents || [])
        .map((v: number) => v < 0 ? null : v)); // Backend returns -1 for missing values. Map to null to avoid wrong sums
      if(monthIndex == undefined) {
        return contingentsForYearPerMP;
      }
      // contingent line in daily values. generate new array (with length = days in month) with distributed contingent per MP
      const numOfValues = this.getNumOfValues(year, monthIndex);
      return contingentsForYearPerMP.map((contingentForYear: (number | null)[]) =>
        this.interpolate(contingentForYear[monthIndex], numOfValues));
    }

    // values
    const relevantValues = monthIndex == undefined ?
      // monthIndex values for bar chart
      dataForRelevantYear :
      // daily values for line chart
      dataForRelevantYear.flatMap(dfcy =>
        dfcy.dailyValues
        .filter(dv => dv.month == monthIndex + 1)); // monthIndex from backend is 1-based

    return (calculateDataSource ?
        relevantValues.map(dv => dv.dataSource) :
        // Backend returns -1 for missing values. Map to null to avoid wrong sums
        relevantValues.map(dv => dv.values.map(v => v < 0 ? null : v)))
      || [[]];
  }

  private static sumMpDataForPeriod(data: ConsumptionDataForYear[], year: number, calculateContingent: boolean, month?: number, purchasedContingentData?: (number | null)[], soldContingentData?: (number | null)[]): (number | null)[] {
    //console.log("sum data ", year, month, data);
    let values = this.getValuesForRelevantTimePeriod(data, year, calculateContingent, month);
    if(values.length == 0) {
      return [];
    }
    //console.log("values after prepare ", year, month, values);

    let numOfValues = this.getNumOfValues(year, month);

    let distributed = this.sumVertically(values as unknown as (number | null)[][], numOfValues);

    // no month: bar chart - return distributed data.
    // data for linechart needs to be cumulated
    let result = month == undefined ? distributed : this.cumulate(distributed);

    // handle contingent trades
    if(calculateContingent && purchasedContingentData && soldContingentData) {
      result = result
        .map((c: number | null, i: number) => {
          if (c == null) { // only show trades if contingent for current month is set
            purchasedContingentData[i] = null;
            soldContingentData[i] = null;
            return null;
          }
          return c - (soldContingentData[i] || 0);
        })
        .map(c => c == null ? null : c < 0 ? 0 : c); // no negative contingent values
    }

    return this.roundAndFilter(result);
  }

  private static roundAndFilter(rawValues: (number | null)[]) {
    const rounded = rawValues.map((value: number | null) => value == null ? null : Math.floor(value)); // no decimal point desired (ELDEXDHUB-3114);
    return rounded.filter(e => e != null).length == 0 ? [] : rounded;
  }

  private static getContingentTradesForPeriod(contingentTrades: TradedContingents[], mode: 'PURCHASE' | 'SALE', year: number, month: number | undefined) {
    let numOfValues = this.getNumOfValues(year, month);

    const tradesForYear: TradedContingents[] = contingentTrades // filter relevant action and relevant year
      .filter(ct =>
        ct.year == year &&
        ct.tradingAction == mode)
      .map(d => ({...d, tradingQuantity: d.tradingQuantity * 1000})); // MWh -> kWh

    // convert to array structure: one value per month
    const yearArray: (number | null)[] = new Array(numOfValues).fill(null)
      .map((_: number | null, i: number) =>
        tradesForYear
          .filter(t => t.month == i+1)
          // sum up trades for month and mode
          .reduce((p: TradedContingents | null, c) => ({...c, tradingQuantity: (p?.tradingQuantity || 0) + c.tradingQuantity}), null)
          ?.tradingQuantity || null);

    if(month == undefined) {
      return yearArray;
    }

    // interpolate month -> daily value
    return this.cumulate(this.interpolate(yearArray[month],numOfValues));
  }

  private static cumulate(distributed: (number | null)[]) {
    let lastCumulationValue: number | null = null;
    let cumulated: (number | null)[] = [distributed[0]];
    for(let i = 1; i < distributed.length; i++) {
      const nextValue: number | null =
        distributed[i] == null ? null :
          cumulated[i-1] == null ?
            lastCumulationValue === null ? distributed[i] :
              lastCumulationValue + distributed[i]! :
            cumulated[i-1]! + distributed[i]!;
      cumulated.push(nextValue);
      lastCumulationValue = nextValue === null ? lastCumulationValue : nextValue;
    }
    // for optical debugging
    /*if(contingent) {
      cumulated = cumulated.map(c => c != null ? c+100000 : c);
    }*/
    return cumulated;
  }

  private static sumVertically(values: (number | null)[][], numOfValues: number) {
    return (values as unknown as (number | null)[][])
      .reduce((sum: (null | number)[], nextArrayForYear: (null | number)[]) => // reduce an array of DataForYear-values to one DataForYear-Array. sum up MPs
          sum.map((s: null | number, i: number) => // add next[0] to sum[0] etc. via map
            nextArrayForYear[i] == null ? s : // null values don't contribute to sum
              s == null ? nextArrayForYear[i] :
                s + nextArrayForYear[i]!),
        new Array(numOfValues).fill(null));
  }

  private static interpolate(value: null | number, numOfValues: number) {
    return Array(numOfValues).fill(value == null ? null : (value/numOfValues));
  }

  private static generateCompleteness(translate: TranslateService, data: ConsumptionDataForYear[], year: number, month: number | undefined): AdditionalChartInfo[] {
    //console.log("generate completeness for ",year, month, data);
    let values = this.getValuesForRelevantTimePeriod(data, year, false, month, true);
    if(values.length == 0) {
      return [];
    }

    let numOfValues = this.getNumOfValues(year, month);
    const iterator = new Array(numOfValues).fill(0);
    const defaultKey = 'DATA_SOURCE_UNSPECIFIED';

    //console.log("values after prepare: ",values);
    return (values as string[][]).reduce((prev: AdditionalChartInfo[], next: string[]) =>
      iterator.map((_, i) => {
        //console.log(i," prev: ",prev[i]?.key, "next: ",next[i]);
        if(next[i] === undefined) next[i] = defaultKey;
        const key = (prev[i].key === defaultKey || prev[i].key === next[i] ?
          next[i] :
          next[i] === defaultKey ? prev[i].key :
            prev[i].key.startsWith('SDAT') && next[i].startsWith('SDAT') ?
              'SDAT_INCOMPLETE' :
              'MIXED');
        return {
          isComplete: (prev[i] === undefined || prev[i].isComplete) && (next[i] === undefined || next[i] !== 'SDAT_INCOMPLETE'),
          key: key,
          dataSource: translate.instant('LabelMeteringDataSource.' + key)
        }
      }), new Array(numOfValues).fill({isComplete: true, dataSource: '', key: defaultKey}));

  }

  public static getMinYear(data: ConsumptionDataCompanyPartner) {
    return Math.min(
      ...data.businessPartners?.flatMap(bp =>
        bp.meteringPoints?.flatMap(m =>
          m.dataForYear?.map(dfy => dfy.year))));
  }

  public static toFilterTree(data: ConsumptionDataCompanyPartner | undefined): TreeNode[] {
    let ret: TreeNode[] = [];
    if(data == undefined) {
      return ret;
    }

    data.businessPartners?.forEach(bp => {
      const children = bp.meteringPoints
        .map(mp => {
          return {
            label: mp.id,
            selected: !mp.excluded,
            data: {
              mpid: mp.id,
              vnb: mp.mpa.id,
              bp: bp.name,
              flagged: mp.excluded
            },
          }});
      const parentSelected = children.reduce((p: boolean | null | undefined, c) => p === undefined || p === c.selected? c.selected : null, undefined);
      ret.push({
        label: bp.name,
        data: bp.name,
        selected: parentSelected === undefined ? true : parentSelected,
        children: children
      });
    })

    return ret;
  }
}
